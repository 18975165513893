<template>
    <BannerTemplate :bannerSource="roadtrip.imageURL">
        <LoadingIcon :isLoading="isLoading" />
        <div class="px-2 mt-n5 roadtrip-settings" v-if="!isLoading">
            <v-container v-if="roadtrip">
                <v-row no-gutters>
                    <v-col class="col-8 display-1 py-0">{{roadtrip.name}}</v-col>
                    <v-col class="col-4 text-end">{{formatDayMonthYear(roadtrip.date)}}</v-col>
                    <v-col class="col-12 py-0">Op {{formatDayMonth(roadtrip.date)}} reed u mee met de Eifel Tour. Op deze pagina vindt u alle informatie over uw deelname.</v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <p class="display-1 mt-2 mb-0">Foto's en review</p>
                        <div class="mb-2" v-if="roadtrip.galleryURL">
                            Via onderstaande knop gaat u naar de galerij van de {{roadtrip.name}}. Hier kunt u de foto's bekijken en een review schrijven.
                        </div>
                        <div class="mb-2" v-if="!roadtrip.galleryURL">
                            De foto's van de {{roadtrip.name}} staan nog niet online. We zijn er druk mee bezig en sturen u een e-mail wanneer ze online staan!
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="py-4" v-if="roadtrip.galleryURL">
                        <v-btn color="primary" block @click="goto(roadtrip.galleryURL) ">
                            {{ `Naar de galerij` }}
                        </v-btn>
                    </v-col>
                </v-row>
                <Invoices :invoices="roadtrip.invoices" v-if="roadtrip.invoices.length > 0"/>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import BannerTemplate from '@/components/common/BannerTemplate';
import Invoices from '@/components/dashboard/Invoices';
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'AfgelopenRoadtrip',
    components: 
    {
        LoadingIcon,
        BannerTemplate,
        Invoices
    },
    mixins: [FormatMixin, RouteMixin, MetaTagsMixin],
    computed: {
        ...mapState('authorizedModule', ['userId']),
    },
    data: () => ({
        downloadIcon: require('@/assets/icons/download.svg'),
        roadtrip: {
            itinerary: {
                details: []
            },
            details: {},
            invoices: []
        },
        roadtripId: '',
        isLoading: false,
        uniqueName: ''
    }),
    mounted () 
    {
        this.uniqueName = this.$route.params.trip;
        this.$store.commit('setFooterVisibility', true);
        this.getRoadtripId();
    },
    metaInfo() {
        return {
        title: this.roadtrip.name ? `| ${this.roadtrip.name}` : `| Afgelopen Roadtrip`,
        meta: [
            this.getKeywords(`${this.roadtrip.name}, roadtrip`),
            this.getMetaDescription(`${this.roadtrip.name}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.roadtrip.name}`, `${this.roadtrip.name}`, this.roadtrip.galleryURL, `${this.roadtrip.name}`),
        ]
        };
    },
    methods: {
        getRoadtripId()
        {
            this.$store.dispatch('roadtripModule/getRoadtripId', this.uniqueName)
                .then(data => {
                    this.roadtripId = data;
                    this.getPassedRoadtrip();
            })
            .catch(() => { 
                this.$store.commit('dialog/showDialog', `Unable to retrieve roadtrip.`);
                this.isLoading = false; 
            });
        },
        getPassedRoadtrip()
        {
            const request = {
                accountId: this.userId,
                roadtripId: this.roadtripId
            }

            this.isLoading = true;
            this.$store.dispatch('roadtripModule/getPassedRoadtrip', request)
                .then(data => {

                this.roadtrip = data;
                this.roadtrip.date = this.getDateOnly(data.date);
                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        }
    }
}
</script>
<style>

</style>